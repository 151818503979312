import { useState } from "react";

// 音檔有可能還沒好，網址無法正常讀取，要先確認可以正常讀取，才開始播放
const checkAudioUrl = async (audioUrl) => {
  try {
    const response = await fetch(audioUrl);
    if (response.ok) {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// 每隔一段時間檢查音檔是否可以正常讀取
const checkAudioInterval = async (audioUrl) => {
  let isAudioReady = false;
  let count = 0;
  const maxCount = 20;

  while (!isAudioReady && count < maxCount) {
    isAudioReady = await checkAudioUrl(audioUrl);
    count++;
    await new Promise((resolve) => setTimeout(resolve, 500));
  }
  return isAudioReady;
};

const useMessageStore = ({ initialMessages }) => {
  const [messages, setMessages] = useState(initialMessages);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);
  const playAudio = (audioUrl) => {
    if (!audioUrl) {
      console.error("Audio URL is required to play audio.");
      return;
    }
    const audioPlayer = document.getElementById("audio-player");
    if (audioPlayer) {
      audioPlayer.src = audioUrl;
      audioPlayer.muted = false;
      audioPlayer.volume = 1;
      audioPlayer.currentTime = 0;
      audioPlayer
        .play()
        .then(() => {
          setIsPlaying(true);
          setCurrentAudio(audioPlayer);
        })
        .catch((error) => {
          console.error("Error playing audio:", error);
          setIsPlaying(false);
          setCurrentAudio(null);
        });

      audioPlayer.onended = () => {
        setIsPlaying(false);
        audioPlayer.src = "";
        setCurrentAudio(null);
      };
    } else {
      console.error("Audio player element not found.");
    }
  };

  const addMessage = async (message) => {
    if (!message.text || message.text === "") return;

    if (isPlaying && currentAudio) {
      stopAudio();
    }
    const audioPlayer = document.getElementById("audio-player");
    if (audioPlayer) {
      audioPlayer.src = "";
      audioPlayer.currentTime = 0;
    }

    const audioUrl = message?.audio;
    if (audioUrl) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { ...message, isLoading: true },
      ]);

      const isAudioReady = await checkAudioInterval(audioUrl);
      if (isAudioReady) {
        const audioPlayer = document.getElementById("audio-player");
        audioPlayer.src = audioUrl;
        audioPlayer.muted = false;
        audioPlayer.volume = 1;
        audioPlayer.currentTime = 0;
        audioPlayer.onplay = () => {
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === message.id ? { ...message, isLoading: false } : msg,
            ),
          );
          setIsPlaying(true);
          setCurrentAudio(audioPlayer);
        };

        audioPlayer.onended = () => {
          setIsPlaying(false);
          audioPlayer.src = "";
          setCurrentAudio(null);
        };
      } else {
        setMessages((prevMessages) => [...prevMessages, message]);
      }
    } else {
      setMessages((prevMessages) => [...prevMessages, message]);
    }
  };

  const updateMessage = async (id, newMessage) => {
    if (!newMessage.text || newMessage.text === "") return;
    if (isPlaying && currentAudio) {
      stopAudio();
    }
    const audioPlayer = document.getElementById("audio-player");
    if (audioPlayer) {
      audioPlayer.src = "";
      audioPlayer.currentTime = 0;
    }

    const audioUrl = newMessage?.audio;
    if (audioUrl) {
      const isAudioReady = await checkAudioInterval(audioUrl);
      if (isAudioReady) {
        const audioPlayer = document.getElementById("audio-player");
        audioPlayer.src = audioUrl;
        audioPlayer.muted = false;
        audioPlayer.volume = 1;
        audioPlayer.currentTime = 0;
        audioPlayer.onplay = () => {
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.id === id ? { ...newMessage, isLoading: false } : msg,
            ),
          );
          setIsPlaying(true);
          setCurrentAudio(audioPlayer);
        };

        audioPlayer.onended = () => {
          setIsPlaying(false);
          audioPlayer.src = "";
          setCurrentAudio(null);
        };
      } else {
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === id ? { ...newMessage, isLoading: false } : msg,
          ),
        );
      }
    } else {
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === id ? { ...newMessage, isLoading: false } : msg,
        ),
      );
    }
  };

  const deleteMessage = (id) => {
    stopAudio();
    const audioPlayer = document.getElementById("audio-player");
    if (audioPlayer) {
      audioPlayer.src = "";
      audioPlayer.currentTime = 0;
    }
    setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== id));
  };

  const stopAudio = () => {
    if (currentAudio) {
      currentAudio.src = "";
      currentAudio.pause();
      currentAudio.currentTime = 0; // 重置音檔時間
      setIsPlaying(false);
      setCurrentAudio(null);
    }
  };

  const resetMessages = () => {
    stopAudio(); // 重置時停止所有播放
    setMessages(initialMessages);
  };

  return {
    messages,
    isPlaying,
    addMessage,
    updateMessage,
    deleteMessage,
    resetMessages,
    stopAudio,
    playAudio,
  };
};

export default useMessageStore;
