import { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Typewriter from "typewriter-effect";
import moment from "moment";
import { marked } from "marked";

const aiName = "AI 老師傅";
const AiCorner = "/images/ai-avatar.svg";

// 配置 marked 選項
marked.setOptions({
  breaks: true, // 啟用換行符轉換為 <br>
  gfm: true, // 啟用 GitHub 風格的 Markdown
});

// 預處理 Markdown 文本，確保列表正確顯示
const preprocessMarkdown = (text) => {
  // 首先處理列表項之間的換行
  let processedText = text.replace(/^(\d+\..+)$/gm, "$1\n\n");

  // 處理有序列表 (1. 2. 等)
  processedText = processedText.replace(
    /^(\d+\.)(\s+)/gm,
    '<span class="inline-block min-w-[1.2em] font-bold">$1</span>$2',
  );

  // 處理無序列表 (*, -, +)
  processedText = processedText.replace(
    /^([*\-+])(\s+)/gm,
    '<span class="inline-block min-w-[1.2em] font-bold">$1</span>$2',
  );

  // 處理嵌套列表 (縮進的列表項)
  processedText = processedText.replace(
    /^(\s+)([*\-+]|\d+\.)(\s+)/gm,
    '$1<span class="inline-block min-w-[1.2em] font-bold">$2</span>$3',
  );

  return processedText;
};

const Message = ({ message }) => {
  const isAi = message.sender === "ai";
  const typewriterRef = useRef(null);
  const prevTextRef = useRef("");
  const contentRef = useRef(null);
  const containerRef = useRef(null);
  const initialRenderRef = useRef(true);

  // 將 Markdown 轉換為 HTML (僅用於 AI 訊息)
  const convertMarkdownToHtml = (text) => {
    if (!isAi) return text; // 使用者訊息不轉換 Markdown
    const processedText = preprocessMarkdown(text);
    return marked.parse(processedText);
  };

  useEffect(() => {
    // 初次渲染時，讓 typewriter 完整顯示訊息
    if (initialRenderRef.current) {
      initialRenderRef.current = false;
      return;
    }

    if (!typewriterRef.current || message.text === prevTextRef.current) return;

    // 計算新增的文字部分
    const newText = message.text.slice(prevTextRef.current.length);

    if (newText.length > 0) {
      // 如果有新文字，直接添加這部分文字
      const newContent = isAi ? convertMarkdownToHtml(newText) : newText;

      // 不刪除之前的內容，只添加新的部分
      typewriterRef.current.changeDelay(30).typeString(newContent).start();
    } else if (message.text.length < prevTextRef.current.length) {
      // 如果新訊息比舊訊息短，表示內容被刪減，需要重新打出所有內容
      const content = isAi ? convertMarkdownToHtml(message.text) : message.text;

      typewriterRef.current
        .deleteAll(1)
        .pauseFor(30)
        .changeDelay(30)
        .typeString(content)
        .start();
    }

    prevTextRef.current = message.text;
  }, [message.text, isAi]);

  // 如果是初始渲染，直接設置內容
  useEffect(() => {
    if (!contentRef.current) return;

    if (initialRenderRef.current && message.text) {
      const content = isAi ? convertMarkdownToHtml(message.text) : message.text;
      contentRef.current.innerHTML = content;
    }
  }, []);

  return (
    <div
      ref={containerRef}
      className={`flex w-fit max-w-full flex-col gap-4 ${
        !isAi ? "self-end" : "self-start"
      }`}
    >
      {isAi && (
        <div className="flex items-center gap-4 text-[4rem]">
          <div className="relative flex h-16 w-16 items-center justify-center overflow-hidden">
            <img
              src={AiCorner}
              alt="ai-avatar"
              className="h-16 w-16 object-cover"
            />
          </div>
          <span className="font-semibold text-white">{aiName}</span>
        </div>
      )}
      <div className={`flex gap-2 ${isAi ? "flex-row" : "flex-row"}`}>
        <div
          className={`rounded-2xl p-8 text-7xl leading-[1.2] ${
            message.sender === "ai" ? "bg-[#FFFFFFCC]" : "bg-[#BAE3BA]"
          }`}
        >
          <span className="h-full whitespace-break-spaces font-medium">
            {isAi ? (
              <>
                <div ref={contentRef} className="hidden"></div>
                <Typewriter
                  autoStart={true}
                  options={{
                    cursor: "",
                    delay: 30,
                    html: true,
                    skipAddStyles: true,
                    cursorClassName: "",
                    wrapperClassName: "h-fit flex flex-col min-h-[4.5rem]",
                  }}
                  onInit={(typewriter) => {
                    typewriterRef.current = typewriter;
                    const content = convertMarkdownToHtml(message.text);
                    typewriter.changeDelay(30).typeString(content).start();
                    prevTextRef.current = message.text;
                  }}
                />
              </>
            ) : (
              <div>{message.text}</div>
            )}
          </span>
        </div>
        <div className="self-end text-5xl text-white">
          {moment(message.createdAt).format("HH:mm")}
        </div>
      </div>
    </div>
  );
};

export default Message;

Message.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.string.isRequired,
    sender: PropTypes.string.isRequired,
    createdAt: PropTypes.string,
    isLoading: PropTypes.bool,
  }).isRequired,
};
