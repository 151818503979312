import { useState, useRef, useCallback } from "react";

export const useTranscription = ({ wsUrl }) => {
  const [websocket, setWebsocket] = useState(null);
  const [sessionTranscript, setSessionTranscript] = useState("");
  const sessionStartedRef = useRef(false);
  const wsRef = useRef(null);

  const handleTranscriptionResult = useCallback((result) => {
    console.log("收到轉錄結果:", result);
    if (result.pipe) {
      if (result.pipe.asr_state === "utterance_begin") {
        sessionStartedRef.current = true;
      }

      if (sessionStartedRef.current) {
        if (result.pipe.asr_sentence) {
          if (result.pipe.asr_final) {
            setSessionTranscript((prev) => {
              const newText = prev
                ? prev + " " + result.pipe.asr_sentence
                : result.pipe.asr_sentence;
              console.log("更新後的文字:", newText);
              return newText;
            });
          }
        }
      }
    }
  }, []);

  const handleConnect = useCallback(() => {
    if (wsRef.current) {
      wsRef.current.close();
    }

    console.log("開始建立 WebSocket 連接");
    const ws = new WebSocket(wsUrl);
    wsRef.current = ws;

    ws.onopen = () => {
      console.log("WebSocket 已連接");
      // 發送更詳細的配置
      const config = {
        language_pipeline: "asr-zh-tw-std",
        audio: {
          sampleRate: 16000,
          channels: 1,
          encoding: "LINEAR16",
        },
        vad: {
          enable: true,
        },
      };
      ws.send(JSON.stringify(config));
      setWebsocket(ws);
    };

    ws.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data);
        console.log("收到 WebSocket 消息:", message);
        if (message.transcription) {
          handleTranscriptionResult(message.transcription);
        }
        if (message.error) {
          console.error("轉錄錯誤:", message.error);
        }
      } catch (e) {
        console.error("解析錯誤:", e);
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket 錯誤:", error);
    };

    ws.onclose = () => {
      console.log("WebSocket 已關閉");
      setWebsocket(null);
      wsRef.current = null;
    };
  }, [wsUrl, handleTranscriptionResult]);

  const sendAudioData = useCallback((audioData) => {
    if (wsRef.current?.readyState === WebSocket.OPEN) {
      try {
        wsRef.current.send(audioData);
        return true;
      } catch (error) {
        console.error("發送音頻數據失敗:", error);
        return false;
      }
    }
    return false;
  }, []);

  const resetTranscript = useCallback(() => {
    console.log("重置轉錄文字");
    setSessionTranscript("");
    sessionStartedRef.current = false;
  }, []);

  return {
    websocket,
    sessionTranscript,
    handleConnect,
    resetTranscript,
    sendAudioData,
    wsRef,
  };
};
