import { useCallback } from "react";
import axios from "axios";

export const useVoiceHandler = ({ apiConfig }) => {
  const sendVoiceMessage = useCallback(
    async (blob, config) => {
      const formData = new FormData();

      // 使用自定義或默認的 payload
      const defaultPayload = {
        chat_type: "ai",
        web_search: false,
        tts: true,
        temperature: 0.7,
        channel_type: "web",
        speech_keywords: "",
        blob_ids: "",
        asr_service: apiConfig.ASR_SERVICE,
      };

      // 優先使用傳入的配置
      const payload = {
        ...defaultPayload,
        ...apiConfig.voicePayload,
        ...config?.payload, // 最高優先級
      };

      // 添加所有 payload 到 formData
      Object.entries(payload).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // 添加文件
      formData.append("file", blob, `${Date.now()}.mp3`);

      try {
        const response = await axios.post(
          `${apiConfig.SERVER_URL}/private/chatrooms/chat/ai/${apiConfig.CHATROOM_ID}/speech`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "x-api-key": apiConfig.API_KEY,
              ...config?.headers,
            },
            params: {
              ...apiConfig.voiceParams,
              ...config?.params,
            },
          },
        );
        return response.data;
      } catch (error) {
        throw new Error("傳送語音錯誤", error);
      }
    },
    [apiConfig],
  );

  return {
    sendVoiceMessage,
  };
};
