import PropTypes from "prop-types";

const aiName = "AI 老師傅";
const AiCorner = "/images/ai-avatar.svg";

const MessageLoading = ({ message }) => {
  const isAi = message.sender === "ai";

  if (message.isLoading) {
    return (
      <div
        className={`flex w-fit max-w-full flex-col gap-4 ${
          !isAi ? "self-end" : "self-start"
        }`}
      >
        {isAi && (
          <div className="flex items-center gap-4 text-[4rem]">
            <div className="relative flex h-16 w-16 items-center justify-center overflow-hidden">
              <img
                src={AiCorner}
                alt="ai-avatar"
                className="h-16 w-16 object-cover"
              />
            </div>
            <span className="font-semibold text-white">{aiName}</span>
          </div>
        )}
        <div className="flex min-h-8 items-center gap-2">
          <div
            className={`rounded-2xl p-8 text-7xl leading-[1.2] ${
              isAi ? "bg-[#FFFFFFCC]" : "bg-[#BAE3BA]"
            }`}
          >
            <div className="flex h-full items-center gap-12">
              <div className="animate-scale h-6 w-6 rounded-full bg-[#3E3D3D]"></div>
              <div className="animate-scale h-6 w-6 rounded-full bg-[#3E3D3D] delay-100"></div>
              <div className="animate-scale h-6 w-6 rounded-full bg-[#3E3D3D] delay-200"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default MessageLoading;

MessageLoading.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.string.isRequired,
    sender: PropTypes.string.isRequired,
    createdAt: PropTypes.string,
    isLoading: PropTypes.bool,
  }).isRequired,
};
