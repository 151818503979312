import { useCallback } from "react";
import axios from "axios";

export const useMessageHandler = ({ apiConfig }) => {
  const sendTextMessage = useCallback(
    async (text, config) => {
      // 使用自定義或默認的 payload
      const defaultPayload = {
        chat_type: "ai",
        message: text,
      };

      // 優先使用傳入的配置
      const payload = {
        ...defaultPayload,
        ...apiConfig.textPayload,
        ...config?.payload, // 最高優先級
      };

      // 默認的 params
      const defaultParams = {
        channel_type: "web",
        tts: true,
      };

      try {
        const response = await axios.post(
          `${apiConfig.SERVER_URL}/private/chatrooms/chat/ai/${apiConfig.CHATROOM_ID}`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": apiConfig.API_KEY,
              ...config?.headers,
            },
            params: {
              ...defaultParams,
              ...apiConfig.textParams,
              ...config?.params,
            },
          },
        );
        return response.data;
      } catch (error) {
        throw new Error("傳送文字訊息錯誤", error);
      }
    },
    [apiConfig],
  );

  return { sendTextMessage };
};
