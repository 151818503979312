import { useState, useRef, useEffect } from "react";
import Recorder from "recorder-core";
import RecordApp from "recorder-core/src/app-support/app";
import "recorder-core/src/engine/mp3";
import "recorder-core/src/engine/mp3-engine";
import { useVoiceHandler } from "./useVoiceHandler";
import { useTranscription } from "./useTranscription";
import { useMessageHandler } from "./useMessageHandler";
import { API_CONFIG } from "../constants";

// 檢查語音識別是否支援指定語言並請求麥克風權限
const checkSpeechRecognitionAndRequestPermission = (
  lang,
  permissionCallback,
) => {
  return new Promise((resolve) => {
    // 先請求錄音權限
    RecordApp.RequestPermission(
      () => {
        // 權限獲得後，檢查語言支援
        const SpeechRecognition =
          window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) {
          permissionCallback && permissionCallback(true);
          resolve(false);
          return;
        }

        const recognition = new SpeechRecognition();
        recognition.lang = lang;

        recognition.onstart = () => {
          recognition.stop();
          permissionCallback && permissionCallback(true);
          resolve(true); // 該語系被支持
        };

        recognition.onerror = (event) => {
          // 部分錯誤可能並非語系不支持而是其他限制
          if (event.error === "not-allowed" || event.error === "network") {
            resolve(true);
          } else {
            resolve(false);
          }
          permissionCallback && permissionCallback(true);
        };

        try {
          recognition.start();
        } catch (error) {
          permissionCallback && permissionCallback(true);
          resolve(false);
        }
      },
      (msg, isUserNotAllow) => {
        console.log(
          (isUserNotAllow ? "UserNotAllow，" : "") + "無法錄音:" + msg,
        );
        permissionCallback && permissionCallback(false, msg, isUserNotAllow);
        resolve(false);
      },
    );
  });
};

export const useVoiceChat = ({ onMessageReceived, onError, config }) => {
  // 檢查必要的配置
  if (!config?.apiConfig?.CHATROOM_ID || !config?.apiConfig?.API_KEY) {
    throw new Error("CHATROOM_ID 和 API_KEY 是必要的配置參數");
  }

  const {
    wsUrl = "wss://api.scfg.io/ws/transcribe/yating",
    sampleRate = 16000,
    bitRate = 16,
    language = window.navigator.language || "zh-TW",
    audioConfig = {
      echoCancellation: true,
      noiseSuppression: true,
      autoGainControl: true,
    },
    apiConfig = {
      SERVER_URL: API_CONFIG.SERVER_URL,
      ASR_SERVICE: API_CONFIG.ASR_SERVICE,
      CHATROOM_ID: config.apiConfig.CHATROOM_ID,
      API_KEY: config.apiConfig.API_KEY,
    },
  } = config || {}; // 如果沒有提供 config，使用空對象

  const { sendVoiceMessage } = useVoiceHandler({
    apiConfig,
  });
  const {
    sessionTranscript,
    handleConnect,
    resetTranscript,
    sendAudioData,
    wsRef,
  } = useTranscription({ wsUrl });
  const { sendTextMessage } = useMessageHandler({ apiConfig });

  // Recorder 相關狀態
  const [isRecording, setIsRecording] = useState(false);
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [isRecordingText, setIsRecordingText] = useState("");
  const recognitionRef = useRef(null);
  const accumulatedTextRef = useRef("");
  const hasSpeechRecognition = useRef(
    !!(window.SpeechRecognition || window.webkitSpeechRecognition),
  );
  const [isLanguageSupported, setIsLanguageSupported] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);
  const [needSendVoice, setNeedSendVoice] = useState(false);

  // 初始化 Recorder 並檢查語言支援
  useEffect(() => {
    RecordApp.Install();

    // 檢查語言支援和請求權限
    const checkLanguageSupport = async () => {
      // if (hasSpeechRecognition.current) {
      //   const isSupported =
      //     await checkSpeechRecognitionAndRequestPermission(language);
      //   setIsLanguageSupported(isSupported);

      //   // 如果語言不支援，則使用 WebSocket 方式
      //   if (!isSupported) {
      //     handleConnect();
      //   }
      // } else {
      //   // 如果不支援原生語音識別，初始化時就準備好 WebSocket
      // }
      handleConnect();
    };

    checkLanguageSupport();
  }, [language]);

  // 開始錄音
  const startRecording = () => {
    if (!isRecording && !isProcessing) {
      resetTranscript();

      const startRecordingProcess = () => {
        console.log("錄音權限已獲得");
        var processTime = 0;
        RecordApp.Start(
          {
            type: "mp3",
            sampleRate,
            bitRate,
            audioTrackSet: audioConfig,
            onProcess: function (
              buffers,
              powerLevel,
              bufferDuration,
              bufferSampleRate,
              newBufferIdx,
              asyncEnd,
            ) {
              processTime = Date.now();

              if (!hasSpeechRecognition.current || !isLanguageSupported) {
                // 確保使用最後一個緩衝區
                const int16Data = buffers[buffers.length - 1];
                sendAudioData(int16Data.buffer);
              }
            },
          },
          () => {
            setIsRecording(true);
            setIsRecordingText("");
            accumulatedTextRef.current = "";

            // 只在支援原生語音識別且語言支援時啟動
            if (hasSpeechRecognition.current && isLanguageSupported) {
              recognitionRef.current = new (window.SpeechRecognition ||
                window.webkitSpeechRecognition)();
              recognitionRef.current.continuous = true;
              recognitionRef.current.interimResults = true;
              recognitionRef.current.lang = language;
              recognitionRef.current.start();

              recognitionRef.current.onresult = (event) => {
                let interimTranscript = "";
                let finalTranscript = "";

                for (let i = event.resultIndex; i < event.results.length; i++) {
                  const transcript = event.results[i][0].transcript;
                  if (event.results[i].isFinal) {
                    finalTranscript += transcript;
                  } else {
                    interimTranscript += transcript;
                  }
                }

                if (finalTranscript) {
                  accumulatedTextRef.current += finalTranscript;
                }

                setIsRecordingText(
                  accumulatedTextRef.current + interimTranscript,
                );
              };

              recognitionRef.current.onerror = (event) => {
                console.log("語音識別錯誤:", event.error);
              };
            }

            const this_ = RecordApp;
            if (RecordApp.Current.CanProcess()) {
              const wdt = (this_.watchDogTimer = setInterval(() => {
                if (wdt !== this_.watchDogTimer) {
                  clearInterval(wdt);
                  return;
                }
                if (Date.now() < this_.wdtPauseT) return;
                if (Date.now() - (processTime || startTime) > 1500) {
                  clearInterval(wdt);
                  console.error(
                    processTime ? "錄音被中斷" : "錄音未能正常開始",
                  );
                  stopRecording();
                }
              }, 1000));
            } else {
              console.warn("當前環境不支持onProcess回調，不啟用watchDogTimer");
            }
            const startTime = Date.now();
            this_.wdtPauseT = 0;
          },
          (msg) => {
            console.log("開始錄音失敗" + msg);
            setIsRecording(false);
          },
        );
      };

      // 如果已經在初始化時請求過權限，直接開始錄音
      // 否則再次請求權限
      if (isLanguageSupported) {
        startRecordingProcess();
      } else {
        RecordApp.RequestPermission(
          startRecordingProcess,
          (msg, isUserNotAllow) => {
            console.log(
              (isUserNotAllow ? "UserNotAllow，" : "") + "無法錄音:" + msg,
            );
          },
        );
      }
    }
  };

  // 停止錄音
  const stopRecording = async () => {
    if (RecordApp.GetCurrentRecOrNull()) {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }

      // 檢查是否有文字內容
      const hasText =
        hasSpeechRecognition.current && isLanguageSupported
          ? isRecordingText
          : sessionTranscript;

      // 停止錄音
      RecordApp.Stop(
        (arrayBuffer, duration, mime) => {
          // 只有在沒有文字的情況下才處理語音檔
          if (
            !hasText &&
            typeof Blob !== "undefined" &&
            typeof window === "object"
          ) {
            const blob = new Blob([arrayBuffer], { type: mime });
            setRecordedBlob(blob);
            setNeedSendVoice(true);
          }
          setIsRecording(false);
        },
        (msg) => {
          console.log("錄音失敗:" + msg);
          setIsRecording(false);
        },
      );

      if (hasText) {
        // 如果有文字，使用文字發送
        await sendMessage(hasText);
      }
    }
  };

  // 發送文字訊息
  const sendMessage = async (text) => {
    setIsProcessing(true);
    try {
      const response = await sendTextMessage(text);
      onMessageReceived?.(response);
    } catch (error) {
      onError?.(error);
    } finally {
      setIsProcessing(false);
    }
  };

  // 處理語音 blob 發送
  useEffect(() => {
    if (recordedBlob && needSendVoice) {
      const sendVoiceData = async () => {
        setIsProcessing(true);
        try {
          const data = await sendVoiceMessage(recordedBlob);
          onMessageReceived?.(data);
        } catch (error) {
          onError?.(error);
        } finally {
          setIsProcessing(false);
          setNeedSendVoice(false);
          setIsRecordingText("");
          resetTranscript();
          setRecordedBlob(null);
        }
      };

      sendVoiceData();
    }
  }, [recordedBlob, needSendVoice]);

  // 清理時關閉 WebSocket
  useEffect(() => {
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, []);

  return {
    // 狀態
    isRecording,
    isProcessing,
    transcript:
      hasSpeechRecognition.current && isLanguageSupported
        ? isRecordingText
        : sessionTranscript,
    isLanguageSupported,

    // 操作方法
    startRecording,
    stopRecording,
    sendMessage,
  };
};
