import React, { useState, useRef, useEffect, useCallback } from "react";
import { FreeMode } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import MessageLoading from "./components/MessageLoading";
import Message from "./components/Message";
import { useVoiceChat } from "./modules/voiceChat/hooks/useVoiceChat";
import useMessageStore from "./modules/voiceChat/hooks/useMessageStore";
import { v4 as uuidv4 } from "uuid";

// 環境變數
const API_URL = process.env.REACT_APP_URL;
const chatRoomId = process.env.REACT_APP_CHATROOM_ID;
const API_KEY = process.env.REACT_APP_API_KEY;

// 商品資訊
const products = [
  {
    name: "草莓大福",
    image: "/images/草莓大福.png",
    description:
      "明月堂的草莓大福，以Q彈有嚼勁的大福皮包裹著精心挑選的紅豆餡，搭配臺灣產的酸甜草莓，讓每一口都充滿驚喜。明月堂堅持傳統工藝，杵搗的大福皮賞味期僅限當日。讓每一顆大福都成為無法冷藏久放的珍貴美味。",
    keywords: [
      "草莓大福",
      "草莓大福禮盒",
      "草莓大福禮盒",
      "草莓",
      "大福",
      "草莓禮盒",
    ],
  },
  {
    name: "牛皮",
    image: "/images/牛皮.png",
    description:
      "銅鑼燒外皮，內裡是Ｑ彈軟糯的麻糬，一口咬下、替平凡的時光增添愜意氣息。明月堂獨有的牛皮也曾是北投加賀屋的房內甜點。",
    keywords: ["牛皮"],
  },
  {
    name: "銅鑼燒",
    image: "/images/銅鑼燒.png",
    description:
      "鬆軟綿密的餅皮，夾著綿綿的紅豆沙餡，甜而不膩的交融在滿足的感動中。",
    keywords: ["銅鑼燒", "銅鑼燒禮盒", "銅鑼燒禮盒", "銅鑼燒禮盒"],
  },
  {
    name: "最中",
    image: "/images/最中.png",
    description:
      "最中是一種歷史悠久的和菓子，外殼用糯米壓製烘烤而成，口感輕盈酥脆，內餡則是細膩綿密的蜜紅豆餡；外脆內軟，讓人一口咬下去即能有多層次的口感。",
    keywords: ["最中", "最終", "最中禮盒", "最中禮盒"],
  },
  {
    name: "花果福酥禮盒",
    image: "/images/花果福酥禮盒.png",
    description:
      "枝葉扶疏花果美，寶島的味覺遊：以台農17號金鑽鳳梨為餡底，輔以三倍有機玫瑰、櫻花及抹茶搭配柑橘，外皮選用歐洲發酵奶油，帶來更易於消化且健康的享受。",
    keywords: ["花果福酥", "花果酥", "花果福酥禮盒"],
  },
];

const useScrollToBottom = () => {
  const isUserScrolling = useRef(false);
  const scrollTimeout = useRef(null);

  const scrollToBottom = useCallback((immediate = false) => {
    // 如果使用者正在滾動，則不執行自動滾動
    if (isUserScrolling.current) return;

    requestAnimationFrame(() => {
      const chatBox = document.querySelector(".chat_box");

      if (!chatBox) return;

      if (immediate) {
        chatBox.scrollTop = chatBox.scrollHeight;
        return;
      }

      const isNearBottom =
        chatBox.scrollHeight - chatBox.scrollTop - chatBox.clientHeight < 250;

      if (isNearBottom) {
        chatBox.scrollTo({
          top: chatBox.scrollHeight,
          // behavior: "smooth",
        });
      }
    });
  }, []);

  // 自動滾動效果
  const startAutoScroll = useCallback(() => {
    scrollToBottom(true);

    const interval = setInterval(() => {
      if (!isUserScrolling.current) {
        scrollToBottom(true);
      }
    }, 10);

    return () => clearInterval(interval);
  }, [scrollToBottom]);

  // 處理使用者滾動的函數
  const handleUserScroll = useCallback(() => {
    isUserScrolling.current = true;

    // 清除之前的 timeout
    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current);
    }

    // 設置新的 timeout，在使用者停止滾動 1 秒後恢復自動滾動
    scrollTimeout.current = setTimeout(() => {
      isUserScrolling.current = false;
    }, 30);
  }, []);

  return { scrollToBottom, startAutoScroll, handleUserScroll };
};

function isWebview() {
  var useragent = navigator.userAgent;
  var regex =
    /(WebView|(iPhone|iPod|iPad)(?!.*Safari\/)|Android.*(wv|.0.0.0))/gi;
  return Boolean(useragent.match(regex));
}

function App() {
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const userTmpIdRef = useRef("");
  const aiMessageIdRef = useRef("");

  const { scrollToBottom, startAutoScroll, handleUserScroll } =
    useScrollToBottom();

  const {
    messages,
    addMessage,
    updateMessage,
    deleteMessage,
    stopAudio,
    isPlaying,
  } = useMessageStore({
    initialMessages: [
      {
        text: "您好，歡迎光臨明月堂！很高興為您服務。請問有什麼需要幫忙的嗎？",
        sender: "ai",
        id: "default message",
        createAt: new Date(),
      },
    ],
  });

  // 處理 AI 回應
  const handleAiResponse = useCallback(
    (data) => {
      if (!data) return;

      // 處理使用者問題顯示
      if (data.question) {
        updateMessage(userTmpIdRef.current, {
          text: data.question?.message,
          sender: "user",
          id: userTmpIdRef.current,
          createAt: new Date().toISOString(),
          isLoading: false,
        });
        scrollToBottom(true);
      }

      // 處理 AI 回應
      if (data.message) {
        const audioBlob = data.blobs?.find((blob) =>
          blob.content_type.startsWith("audio/"),
        );

        // 更新已存在的 AI 載入訊息，而不是新增
        if (aiMessageIdRef.current) {
          updateMessage(aiMessageIdRef.current, {
            text: data.message,
            audio: audioBlob?.url,
            sender: "ai",
            id: aiMessageIdRef.current,
            createAt: new Date().toISOString(),
            isLoading: false,
          });
        } else {
          // 如果沒有預先建立的 AI 訊息 ID，則新增一個
          const tmpIdAi = uuidv4();
          addMessage({
            text: data.message,
            audio: audioBlob?.url,
            sender: "ai",
            id: tmpIdAi,
            createAt: new Date().toISOString(),
            isLoading: false,
          });
        }

        scrollToBottom(true);
      }
    },
    [addMessage, updateMessage, scrollToBottom],
  );

  // 處理錯誤
  const handleError = useCallback(
    (error) => {
      console.error("請求失敗", error);

      // 如果有預先建立的 AI 訊息，則更新它
      if (aiMessageIdRef.current) {
        updateMessage(aiMessageIdRef.current, {
          text: "系統忙碌中，請稍後再試",
          sender: "ai",
          id: aiMessageIdRef.current,
          createAt: new Date().toISOString(),
          isLoading: false,
        });
      } else {
        // 否則新增一個錯誤訊息
        addMessage({
          text: "不好意思，請您再說一次。",
          sender: "ai",
          createAt: new Date().toISOString(),
          isLoading: false,
        });
      }

      scrollToBottom(true);
    },
    [addMessage, updateMessage, scrollToBottom],
  );

  // 使用 voiceChat 模組
  const {
    isRecording,
    isProcessing: isAnswering,
    transcript: isRecordingText,
    startRecording,
    stopRecording,
  } = useVoiceChat({
    onMessageReceived: handleAiResponse,
    onError: handleError,
    config: {
      wsUrl: `${window.location.protocol === "https:" ? "wss:" : "ws:"}//api.scfg.io/ws/transcribe/yating`,
      apiConfig: {
        SERVER_URL: API_URL,
        CHATROOM_ID: chatRoomId,
        API_KEY: API_KEY,
        textPayload: {
          chat_type: "ai_query",
          speech_keywords: [
            "草莓大福",
            "草莓大福禮盒",
            "草莓大福禮盒",
            "草莓",
            "大福",
            "草莓禮盒",
            "牛皮",
            "銅鑼燒",
            "銅鑼燒禮盒",
            "銅鑼燒禮盒",
            "銅鑼燒禮盒",
            "最中",
            "最終",
            "最中禮盒",
            "最中禮盒",
            "花果福酥",
            "花果酥",
            "花果福酥禮盒",
          ],
        },
        voicePayload: {
          chat_type: "ai_query",
          speech_keywords: [
            "草莓大福",
            "草莓大福禮盒",
            "草莓大福禮盒",
            "草莓",
            "大福",
            "草莓禮盒",
            "牛皮",
            "銅鑼燒",
            "銅鑼燒禮盒",
            "銅鑼燒禮盒",
            "銅鑼燒禮盒",
            "最中",
            "最終",
            "最中禮盒",
            "最中禮盒",
            "花果福酥",
            "花果酥",
            "花果福酥禮盒",
          ],
        },
      },
    },
  });

  // 商品輪播
  const swiperRef = useRef(null);
  // 展示商品
  const [activeIndex, setActiveIndex] = useState(0); // 預設第一個顯示第一個產品

  const handleSlideChange = (index) => {
    // 移動到指定商品
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
    setActiveIndex(index);
  };

  // 清除載入中的訊息
  const clearMessages = () => {
    const isLoadingMessages = messages.filter(
      (message) =>
        message.isLoading || message.text === "系統忙碌中，請稍後再試",
    );
    isLoadingMessages.forEach((message) => {
      deleteMessage(message.id);
    });
    stopAudio();
  };

  // 開始錄音
  const handleStartRecording = async () => {
    clearMessages();
    if (!isRecording && !isAnswering) {
      stopAudio();

      const tmpId = uuidv4();
      userTmpIdRef.current = tmpId;

      const aiTmpId = uuidv4();
      aiMessageIdRef.current = aiTmpId;

      await startRecording();
      addMessage({
        text: "錄音開始",
        sender: "user",
        id: tmpId,
        createAt: new Date().toISOString(),
        isLoading: true,
      });
      scrollToBottom(true);
    }
  };

  // 停止錄音
  const handleStopRecording = () => {
    if (isRecording) {
      stopRecording();

      setTimeout(() => {
        addMessage({
          text: "ai 正在思考",
          sender: "ai",
          id: aiMessageIdRef.current,
          createAt: new Date().toISOString(),
          isLoading: true,
        });

        scrollToBottom(true);
      }, 1000);
    }
  };

  // 結束對話並清除資料
  const resetChat = () => {
    stopAudio();
    aiMessageIdRef.current = "";
    userTmpIdRef.current = "";
    clearMessages();
    // 清除所有訊息，只保留初始訊息
    const initialMessage = {
      text: "您好，歡迎光臨明月堂！很高興為您服務。請問有什麼需要幫忙的嗎？",
      sender: "ai",
      id: "default message",
      createAt: new Date(),
    };
    // 清除所有訊息
    messages.forEach((message) => deleteMessage(message.id));
    // 添加初始訊息
    addMessage(initialMessage);
  };

  // 更新使用者訊息文字 (語音辨識)
  useEffect(() => {
    if (!isRecordingText || !userTmpIdRef.current) return;

    updateMessage(userTmpIdRef.current, {
      text: isRecordingText,
      sender: "user",
      id: userTmpIdRef.current,
      createAt: new Date().toISOString(),
      isLoading: false,
    });

    scrollToBottom(true);
  }, [isRecordingText]);

  // 處理 AI 動畫狀態
  useEffect(() => {
    let cleanup;

    if (isPlaying) {
      setIsAudioPlaying(true);
      cleanup = startAutoScroll();
    } else {
      setIsAudioPlaying(false);
      stopAudio();
    }

    return () => {
      if (cleanup) cleanup();
    };
  }, [isPlaying, startAutoScroll, stopAudio]);

  // 檢查最新的對話中是否有提到商品名稱,有就顯示該商品
  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom(true);
      const lastMessage = messages[messages.length - 1];
      const foundProduct = products.find((product) =>
        product.keywords.some(
          (kw) => lastMessage.text && lastMessage.text.includes(kw),
        ),
      );
      if (foundProduct) {
        const index = products.indexOf(foundProduct);
        handleSlideChange(index);
      }
    }
  }, [messages]);

  useEffect(() => {
    // 檢查是否在應用程式環境中，如果是則轉向使用預設瀏覽器
    if (isWebview()) {
      let currentUrl = window.location.href;
      if (!currentUrl.includes("openExternalBrowser=1")) {
        currentUrl +=
          (currentUrl.includes("?") ? "&" : "?") + "openExternalBrowser=1";
        window.location.href = currentUrl;
      }
    }

    const handleVisibilityChange = () => {
      if (document.hidden) {
        stopAudio();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // 正確的清理函數寫法
    return () => {
      aiMessageIdRef.current = "";
      userTmpIdRef.current = "";
      clearMessages();

      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <div className="App fixed flex h-full w-screen flex-col overflow-y-auto overflow-x-hidden">
      {/* logo */}
      <div className="flex items-center justify-between px-24 pb-32 pt-40 sm:px-48">
        <div className="flex flex-col items-center gap-10">
          <img
            src="/images/qr-web.png"
            alt="official web qr-code"
            width={412}
            height={412}
            loading="lazy"
            className="h-[25.75rem] w-[25.75rem]"
          />
          <span className="text-6xl font-semibold text-[#555555]">
            官方網站
          </span>
        </div>
        <img
          src="/images/logo.png"
          alt="logo"
          width={616}
          height={300}
          loading="lazy"
          className="h-[18.8rem] w-[38.5rem]"
          onClick={resetChat}
        />
        <div className="flex flex-col items-center gap-10">
          <img
            src="/images/qr-fanpage.png"
            alt="fan page qr-code"
            width={412}
            height={412}
            loading="lazy"
            className="h-[25.75rem] w-[25.75rem]"
          />
          <span className="text-6xl font-semibold text-[#555555]">
            粉絲專頁
          </span>
        </div>
      </div>
      {/* ai chat */}
      <main
        className="grid flex-1 grid-cols-2 px-24 py-32"
        style={{
          backgroundImage: `url(${"/images/背景.png"})`,
          backgroundSize: "cover",
        }}
      >
        <div className="relative flex h-full items-center justify-center">
          <div className="absolute bottom-0 left-0 right-0 top-0 flex h-full w-full flex-col gap-7 overflow-y-auto rounded-2xl bg-black bg-opacity-20 p-8">
            <div
              className="chat_box relative flex flex-grow flex-col gap-3 overflow-y-auto scroll-smooth px-5 py-2"
              onScroll={handleUserScroll}
            >
              {messages.map((message, index) => (
                <React.Fragment key={index}>
                  {message.isLoading ? (
                    <MessageLoading message={message} />
                  ) : (
                    <Message message={message} />
                  )}
                </React.Fragment>
              ))}
              <div className="bottom_element invisible" />
            </div>
          </div>
        </div>
        <div className="relative flex h-full items-center justify-center">
          <img
            loading="lazy"
            width={1615}
            height={1615}
            src={isAudioPlaying ? "/images/ai.gif" : "/images/ai-default.png"}
            alt="ai"
            className="aspect-square h-full w-[101rem] max-w-none"
          />
          <audio
            id="audio-player"
            src="https://teamsyncblob.s3.ap-southeast-1.amazonaws.com/ChatRoom_Speech/2cac0736-24dc-42ea-a7c6-7aa533511b44/ad10e402-aeca-4f23-a375-44235cd77b1f.mp3"
            autoPlay
            playsInline
            controlsList="nodownload"
            className="hidden"
          />

          <button
            onClick={isRecording ? handleStopRecording : handleStartRecording}
            disabled={isAnswering}
          >
            <img
              loading="lazy"
              width={435}
              height={425}
              src={
                isRecording
                  ? "/images/mic-clicked.svg"
                  : "/images/mic-default.svg"
              }
              alt="mic"
              className={`absolute left-1/2 top-1/2 h-auto w-[27rem] translate-x-[-50%] translate-y-[45%] ${isAnswering ? "opacity-50" : "opacity-100"}`}
            />
          </button>

          {/* mic */}
        </div>
      </main>
      {/* product list*/}
      <div className="w-full py-24 pl-20">
        <Swiper
          slidesPerView={3.5}
          breakpoints={{
            0: {
              slidesPerView: 3.5,
              spaceBetween: 20, // 可根據需要調整間距
            },
            768: {
              spaceBetween: 20,
            },
            1024: {
              spaceBetween: 30,
            },
            1440: {
              spaceBetween: 60,
            },
          }}
          freeMode={true}
          className="w-full !pr-20"
          modules={[FreeMode]}
          ref={swiperRef}
        >
          {products.map((product, index) => (
            <SwiperSlide key={index} onClick={() => handleSlideChange(index)}>
              <div
                className={`cursor-pointer overflow-hidden rounded-[2rem] border-4 border-[#949494] ${
                  activeIndex === index
                    ? "!border-main-red !bg-main-red shadow"
                    : "bg-[#949494]"
                }`}
              >
                <div
                  className="mx-auto h-96 w-full max-w-[52rem] rounded-tl-[2rem] rounded-tr-[2rem] bg-cover bg-center bg-no-repeat"
                  style={{ backgroundImage: `url(${product.image})` }}
                  role="img"
                  aria-label={product.name}
                ></div>
                <div
                  className={`flex items-center justify-center bg-white px-4 py-8 text-6xl text-[#555555] ${
                    activeIndex === index
                      ? "!bg-main-red font-semibold !text-white"
                      : ""
                  }`}
                >
                  {product.name}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="h-8 min-h-8 w-full bg-[#DCD2C5]"></div>
      {/* product detail */}
      <div className="flex flex-1 flex-col items-center justify-center gap-[7.5rem] px-24 py-[8.5rem] sm:flex-row">
        <img
          width={1200}
          height={894}
          src={products[activeIndex].image}
          alt={products[activeIndex].name}
          className="h-[55.875rem] w-[75rem] flex-shrink-0 overflow-hidden rounded-[2rem] object-cover"
        />
        <div className="flex w-full flex-col gap-[6.5rem]">
          <span className="text-8xl font-semibold text-main-red">
            {products[activeIndex].name}
          </span>
          <p className="whitespace-break-spaces text-[4rem] font-normal leading-[6rem]">
            {products[activeIndex].description}
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
